.totp-success__text-container {
  text-align: start;
}

.totp-success__flex-auto {
  flex: auto;
}

.totp-success__flex-none {
  flex: none;
}
